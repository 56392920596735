<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_ANSWERS'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            questionnaireAnswersFields: [
                {
                    name: 'started_at_date_time',
                    title: this.getI18n(i18nKey, 'FIELDS.started_at_date_time'),
                    sortField: 'started_at_date_time',
                    formatter: this.formatDateTime,
                },
                {
                    name: 'ended_at_date_time',
                    title: this.getI18n(i18nKey, 'FIELDS.ended_at_date_time'),
                    sortField: 'ended_at_date_time',
                    formatter: this.formatDateTime,
                },
                {
                    name: 'business_unit.name',
                    title: this.getI18n(
                        'BUSINESS_UNITS',
                        'TITLES.business_unit'
                    ),
                    sortField: 'business_units|business_units.name',
                },
                {
                    name: 'user.name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    sortField: 'users|users.name',
                },
                {
                    name: 'point_of_sale.name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    sortField: 'point_of_sales|point_of_sales.name',
                },
                {
                    name: 'point',
                    title: this.getI18n(i18nKey, 'FIELDS.point'),
                    sortField: 'point',
                    formatter: (value) => {
                        return this.formatPlaceLink(value)
                    },
                },
                {
                    name: 'questionnaire.name',
                    title: this.getI18n(
                        'QUESTIONNAIRES',
                        'TITLES.questionnaire'
                    ),
                    sortField: 'questionnaires|questionnaires.name',
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'answers',
                    title: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'FIELDS.answer',
                        modifier: 2,
                    }),
                    visible: this.$can('READ', 'ANSWER'),
                },
            ],
        }
    },
    methods: {
        importStatuses(value) {
            return this.getI18n(i18nKey, `STATUSES.${value}`)
        },

        importTypes(value) {
            return this.getI18n(i18nKey, `TYPES.${value}`)
        },
    },
}
</script>
